
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Contact } from '../models/static-contents/contact.interface';
import { translateApi } from '../utils/translate-api';
import ContactDirectionsItem from './ContactDirectionsItem.vue';

@Component({
  components: {
    ContactDirectionsItem,
  },
  filters: {
    translateApi,
  },
})
export default class ContactDirections extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  contactContent!: Contact;
}
