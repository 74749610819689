import { Locale } from '@/models/shared/locale.interface';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const appModule = namespace('app');

@Component
export class UsePageHeaderMixin extends Vue {
  @appModule.Action('setHeaderHtml')
  setHeaderHtml!: (payload: string) => void;

  @appModule.Getter('currentLocale')
  currentLocale!: Locale;

  get headerHtml() {
    return '';
  }

  @Watch('headerHtml', { immediate: true })
  onHeaderChanged() {
    this.setHeaderHtml(this.headerHtml);
  }

  @Watch('currentLocale', { immediate: true, deep: true })
  onCurrentLanguageChanged() {
    this.setHeaderHtml(this.headerHtml);
  }
}
