
import { Component, Mixins } from 'vue-property-decorator';
import { UsePageHeaderMixin } from '@/mixins/use-page-header.mixin';
import VueRecaptcha from 'vue-recaptcha';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';
import FooterContactInfo from '../components-smart/FooterContactInfo.vue';
import ContactDirections from '../components/ContactDirections.vue';
import { ValidationObserver } from 'vee-validate';
import FormInput from '../components/FormInput.vue';
import { namespace } from 'vuex-class';
import MeetingRoomBooking from '@/models/facilities/meeting-room-booking.class';
import { SelectOption } from '@/models/shared/select-option.interface';
import { User } from '@/models/users/user.class';
import moment from 'moment';
import RoomType from '@/models/facilities/room-type.class';
import env from '../../env.config';
import { translateApi } from '@/utils/translate-api';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import Multiselect from 'vue-multiselect';

const appModule = namespace('app');
const authModule = namespace('auth');
const roomTypesModule = namespace('roomTypes');
const locationsModule = namespace('locations');
import Location from '@/models/locations/location.class';

@Component({
  components: {
    ContactDirections,
    ValidationObserver,
    FormInput,
    VueRecaptcha,
    FooterContactInfo,
    Multiselect,
  },
  filters: { translateApi },
})
export default class FacilitiesBooking extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @appModule.Action('submitMeetingRoomBookingForm')
  submitMeetingRoomBookingForm!: (
    meetingRoomBookingForm: MeetingRoomBooking,
  ) => Promise<MeetingRoomBooking>;

  @roomTypesModule.Action('fetchOne')
  fetchRoomType!: (id: string) => Promise<RoomType>;

  @roomTypesModule.Getter('selectedRoomType')
  roomType!: RoomType;

  @locationsModule.Action('FETCH_UNPAGINATED')
  fetchLocations!: (payload?: FetchAllParams) => Promise<Location[]>;

  @locationsModule.Getter('ALL')
  locations!: Location[];

  @authModule.Getter('authenticatedUser')
  user!: User;

  meetingRoomBookingForm: MeetingRoomBooking = new MeetingRoomBooking();
  showSuccessMessage = false;
  submitBtnEnabled = false;
  isLoading = false;
  errorTranslationKey = '';
  cateringToBeAdded = {
    value: '',
    text: '',
  };
  partDayToBeAdded = {
    value: '',
    text: '',
  };

  now = new Date();
  minDate = moment(this.now).format('YYYY-MM-DD');

  async mounted() {
    const bookingRoomString = window.sessionStorage?.getItem('bookingRoom');
    if (bookingRoomString) {
      const selectedData = JSON.parse(bookingRoomString);
      this.meetingRoomBookingForm = selectedData;
      this.cateringToBeAdded = selectedData.formulaCatering;
      this.partDayToBeAdded = selectedData.dayPart;
    }
  }

  async created() {
    const roomTypeId: string = (this.$route.params.roomTypeId as string) || '';

    await this.fetchRoomType(roomTypeId);
    await this.fetchLocations();
  }

  get locale() {
    return this.$i18n.locale;
  }

  get headerHtml() {
    if (this.roomType && this.locale) {
      return `<h2 class="text-white">${this.roomType.name[this.locale]}</h2>`;
    }

    return `<h2 class="text-white">${this.$t('facilities.title')}</h2>`;
  }

  getImageUrl(path) {
    return path && `${env.OKF_API_ROOT}/${path.replace('uploads/', '')}`;
  }

  get dayPartOptions(): SelectOption[] {
    return [
      { value: '', text: `${this.$t('facilities.selectOption')}` },
      { value: 'morning', text: `${this.$t('facilities.dayPartOptions.morning')}` },
      { value: 'afternoon', text: `${this.$t('facilities.dayPartOptions.afternoon')}` },
      { value: 'all', text: `${this.$t('facilities.dayPartOptions.all')}` },
    ];
  }

  get typeCateringOptions(): SelectOption[] {
    return [
      { value: 'noCatering', text: `${this.$t('facilities.noCatering')}` },
      { value: 'coffee', text: `${this.$t('facilities.coffee')}` },
      { value: 'breakfast', text: `${this.$t('facilities.breakfast')}` },
      { value: 'lunch', text: `${this.$t('facilities.lunch')}` },
      { value: 'allIn', text: `${this.$t('facilities.allIn')}` },
    ];
  }

  getLocationsNames(locationsIds) {
    const locations = locationsIds?.map(locationId =>
      this.locations.find(location => location._id === locationId),
    );

    return (
      locations &&
      locations.length &&
      locations.map(location => location?.name[this.$i18n.locale])?.join(', ')
    );
  }

  async onSubmitForm() {
    this.isLoading = true;
    this.errorTranslationKey = '';
    const peoplePayload: number = Math.floor(this.meetingRoomBookingForm.people);
    const roomTypeId = this.$route.params.roomTypeId as string;
    const date = new Date(this.meetingRoomBookingForm.date).toISOString();
    const payload = {
      ...this.meetingRoomBookingForm,
      date,
      userId: this.user?._id || null,
      status: 'new',
      catering: true,
      roomTypeId: roomTypeId,
      formulaCatering: this.cateringToBeAdded.value || '',
      dayPart: this.partDayToBeAdded.value || '',
      people: peoplePayload,
    };

    if (this.user?._id) {
      const contactResponse = await this.submitMeetingRoomBookingForm(
        payload as MeetingRoomBooking,
      );

      if (typeof contactResponse === 'string') {
        this.errorTranslationKey = contactResponse;
      }

      this.resetMeetingRoomBookingForm();
      this.showSuccessMessage = true;
      this.isLoading = false;
      window.sessionStorage.removeItem('bookingRoom');
    } else {
      const bookingFormString = JSON.stringify({
        ...payload,
        formulaCatering: this.cateringToBeAdded,
        dayPart: this.partDayToBeAdded,
      });
      window.sessionStorage.setItem('bookingRoom', bookingFormString);

      await this.$router.push({
        name: 'login',
        query: { redirect: 'facilities' },
      });
    }
  }

  resetMeetingRoomBookingForm() {
    this.meetingRoomBookingForm = new MeetingRoomBooking();
    this.submitBtnEnabled = false;
  }
}
