import { BaseModel } from '../base-model.class';
import MeetingRoomBookingAdds from '@/models/facilities/meeting-room-booking-adds.class';

export class Offer {
  adds: MeetingRoomBookingAdds[] = [];
}

export default class MeetingRoomBooking extends BaseModel {
  userId = '';
  status = '';
  date = '';
  dayPart = '';
  people = 0;
  catering = false;
  roomTypeId = '';
  formulaCatering = 'noCatering';
  accessibleWheelchair = false;
  pcRoom = false;
  whiteboard = false;
  extraNotes = '';
  invoiceId = '';
  rejectMessage = '';
  priceOfferTimeStamp? = '';
  offer = new Offer();
  expiryDate?: string;
}
