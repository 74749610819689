
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ContactDirectionsItem extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  id!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  visible!: boolean;
}
